import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const LogoWrap = styled.div`
  margin: auto -50px;
  // font-family: 'Varela Round';
  font-size: 150%;

  @media (max-width: 768px) {
    margin: auto;
  }
`
const Logo = () => {
  return (
    <LogoWrap as={Link} to="/">
      Saadify
    </LogoWrap>
  )
}

export default Logo
