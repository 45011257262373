import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

const FooterWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  padding: 80px 1%;

  @media (max-width: 768px) {
    width: 100%
  }
`

const FooterBox = styled.div`
  padding: 0px 1%;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  -webkit-box-align: stretch;
  align-items: stretch;
  width: 25%;
  color: DimGrey;
  word-wrap: break-word;

  @media (max-width: 768px) {
    padding: 2%;
    text-align: center;
    width: 100%;
    line-height: 200%
  }
`

const FooterLink = styled(Link)`
  text-decoration: none;
  color: #111;
  display: inline-block;
  white-space: nowrap;
  transition: all 200ms ease-in;
  position: relative;

  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: ".";
    color: transparent;
    background: DarkGrey;
  }

  :hover {
    color: DarkGrey;
  }
`

const Footer = () => (
  <FooterWrapper>
    <FooterBox>
      <FooterLink to="/">© Saadify</FooterLink>
    </FooterBox>
    <FooterBox>
      Nos Services
      <FooterLink to="/developpement-web">Développement Web</FooterLink>
      <FooterLink to="/accompagnement-numerique">Accompagnement Numérique</FooterLink>
    </FooterBox>
    <FooterBox>
      L'Entreprise
      <FooterLink to="/realisations">Réalisations</FooterLink>
      <FooterLink to="/a-propos">A Propos</FooterLink>
      <FooterLink to="/contact">Contact</FooterLink>
    </FooterBox>
    <FooterBox>
      Ressources
      <FooterLink to="/mentions-legales">Mentions Légales</FooterLink>
      <FooterLink to="/conditions-generales-vente">Conditions Générales de Vente</FooterLink>
      <FooterLink to="/cookies">Politique d'utilisation des cookies</FooterLink>
    </FooterBox>
  </FooterWrapper>
)

export default Footer
