/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Footer from "./footer"
import Navbar from "./Navbar/Navbar"
import CookieConsent from "react-cookie-consent";
import { useLocation } from "@reach/router" // this helps tracking the location
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import "./layout.css"
import { Link } from "gatsby"
import { Gtag } from "./gtag"
import FloatingWhatsApp from 'react-floating-whatsapp'
import logopic from '../images/saadify-logo.png'

const Layout = ({ children }) => {
  return (
    <>
      <Gtag/>
      <Navbar/>
      <div
        style={{
          margin: `0 auto`,
          padding: `0 1.0875rem 1.45rem`,
        }}
      >
        <main>{children}</main>
      </div>

      <CookieConsent
          flipButtons
          acceptOnScroll={true}
          buttonText="OK"
          style={{
            padding: '0 30px 0 0'
          }}
          cookieName="gatsby-gdpr-google-analytics"
          onAccept={() => {
            initializeAndTrack(useLocation)
          }}
      
        >
          En poursuivant la navigation, vous acceptez la <Link style={{ color:`slategrey` }} to="/cookies">politique d'utilisation des cookies.</Link>

      </CookieConsent>

      <FloatingWhatsApp
        phoneNumber='33665361307'
        accountName='Saadify'
        statusMessage='Réponse sous 12h'
        chatMessage='Hello 👋 Que puis-je faire pour vous ?'
        placeholder='Votre message...'
        avatar={logopic}
      />
      <Footer/>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
