import React from "react"
import { Helmet } from "react-helmet"


export const Gtag = () => (
    <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-194042183-1"></script>
        <script>
        {`window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());

        gtag('config', 'UA-194042183-1');`}
        </script>
    </Helmet>
)